import React from 'react'
import Layout from "../components/Layout"

export default class AboutPage extends React.Component {

  render() {
    return (
      <div>
        <Layout>
          <div className="aga-container aga-fade-in">
            <h1>About</h1>
            <h2>About this site</h2>        
            <p>The <a href="https://www.gastro.org" target="_blank" rel="noopener noreferrer">American Gastroenterology Association (AGA)</a> recognized the need to improve timely referral and diagnosis and to encourage collaboration between primary care and gastroenterologists. The AGA brought together a team of leading primary care physicians and gastroenterologists to develop an education resource for health care providers to foster collaboration between primary care practitioners and gastroenterologists to provide optimal, evidence-based care for patients with IBD. </p>
            <h2>How to use the site</h2>
            <p>The educational materials available on this site are intended for primary care practitioners and gastroenterologists. On this site, you will find three case studies following a patient through Diagnosis, Medication Management and Health Maintenance with corresponding podcasts. Learners can review the cases, listen to the podcasts and complete the posttest evaluation with a passing grade to receive 3.00 <em>AMA PRA Category 1 credits</em>.</p>
            <p>After completing the activities, you will be able to:</p>
            <ul>
              <li>Recognize when patient referral to a gastroenterologist from primary care is appropriate and timely</li>
              <li>Describe safety and efficacy of current and emerging medications for ulcerative colitis and Crohn’s disease</li>
              <li>Describe strategies for modifying treatment regimens for symptom relief and to achieve remission</li>
            </ul>
            <p>Learners will need to create an AGA website account to complete the activity posttest and collect CME credits.</p>
            <p>All education activities on this site are authored by board-certified health care professionals, both primary care practitioners and gastroenterologists. All education activities on this site have been reviewed and accredited by the American Gastroenterological Association.</p>
            <p>This website is a resource for physicians and health care professionals. The information found here is not a substitute for medical advice, diagnosis or treatment.</p>
            <p><em>This website is supported by an unrestricted medical education grant from Takeda Pharmaceuticals U.S.A., Inc.</em></p>
          </div>
        </Layout>
      </div>
    )
  }
}